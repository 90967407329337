import React from "react"
import GoogleMapReact from "google-map-react"
import styled from "styled-components"

const defaultProps = {
  center: {
    lat: 49.219397141930905,
    lng: -122.60248661331553,
  },
  zoom: 18,
}

// ///////////////////////////////////////
// // 🛠 Component
// ///////////////////////////////////////
const Marker = ({ text, uri }) => (
  <MarkerWrapper href={uri} target="_blank">
    {text}
  </MarkerWrapper>
)

const GoogleMap = () => (
  <div style={{ height: "50vh", width: "100%" }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: "AIzaSyDymH_rXEznX4wAHgANBIdL34ne1su0k8k" }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
    >
      <Marker
        lat={49.219437}
        lng={-122.603132}
        text={"MHWP Law"}
        uri="https://goo.gl/maps/sFnYu2vJ9mxxFz7f6"
      />
      <Marker
        lat={49.2194247379722}
        lng={-122.60230983986924}
        text={"MHWP Law"}
        uri="https://goo.gl/maps/GvT8QBQBhQYeef5d9"
      />
    </GoogleMapReact>
  </div>
)
export default GoogleMap

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const MarkerWrapper = styled.a`
  color: ${({ theme }) => theme.white};
  background: ${({ theme }) => theme.green};
  padding: 10px;
  display: block;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  width: 100px;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
    0px 10px 10px -5px rgba(0, 0, 0, 0.04);
`
