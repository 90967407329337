import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import posed from "react-pose"
import styled from "styled-components"
import Layout from "../components/layout"
import SeoComponent from "../components/seoComponent"
import PageHeader from "../components/pageHeader/pageHeader"
import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import Container from "../components/Container"
import PhoneIcon from "../images/phone-icon.svg"
import MailIcon from "../images/mail-icon.svg"
import OfficePic from "../images/office.jpg"
import LocationIcon from "../images/location-icon.svg"
import Map from "../components/contact/map"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const ContactPage = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    let fadeIn = setTimeout(() => setIsVisible(true), 500)
    return () => {
      clearTimeout(fadeIn)
    }
  }, [])

  const { page } = useStaticQuery(CONTACT_PAGE_CONTENT)

  return (
    <Layout>
      <Header />
      <SeoComponent seo={page.seo} />
      <PageAnimationWrapper pose={isVisible ? "visible" : "hidden"}>
        <PageHeader
          heading={page.acfPageHeader.heading}
          subHeading={page.acfPageHeader.subHeading}
          bg={page.acfPageHeader.headerImage.sourceUrl}
          compact={page.acfPageHeader.compact}
          mobileHeight="154px"
        />
        <Container bg="#f4f5f5">
          <ContactInner>
            <ContacDetails>
              <h3>CONTACT US</h3>
              <p className="intro">{page.acfContact.introText}</p>
              <div className="contacts">
                <p>
                  <img src={PhoneIcon} alt="Phone" />{" "}
                  <a href={"tel:" + page.acfContact.phone}>
                    {page.acfContact.phone}
                  </a>
                </p>
                <p>
                  <img src={MailIcon} alt="Phone" />{" "}
                  <a href={"mailto:" + page.acfContact.email}>
                    {page.acfContact.email}
                  </a>
                </p>
                <p>
                  <img src={LocationIcon} alt="Address 1" />
                  <a
                    href="https://goo.gl/maps/sFnYu2vJ9mxxFz7f6"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {page.acfContact.address.line1}
                    {page.acfContact.address.line2}
                    {page.acfContact.address.line3}
                  </a>
                </p>
                <p>
                  <img src={LocationIcon} alt="Address 2" />
                  <a
                    href="https://goo.gl/maps/GvT8QBQBhQYeef5d9"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {page.acfContact.address2.line1}
                    {page.acfContact.address2.line2}
                    {page.acfContact.address2.line3}
                  </a>
                </p>
              </div>
              <img src={OfficePic} alt="office" />
            </ContacDetails>
            <FormWrapper>
              <h3>CONTACT FORM</h3>
              <form
                name="contact"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                action="/success"
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                <div>
                  <label htmlFor="name">Name:</label>
                  <input type="text" name="Name" id="name" />
                </div>
                <div>
                  <label htmlFor="phone">Phone:</label>
                  <input type="tel" name="Phone" id="phone" />
                </div>
                <div>
                  <label htmlFor="email">Email:</label>
                  <input type="email" name="Email" id="email" />
                </div>
                <div>
                  <label htmlFor="message">Message:</label>
                  <textarea rows="5" name="Message" id="message" />
                </div>
                <div className="form-footer">
                  <button type="submit">SEND</button>
                </div>
              </form>
            </FormWrapper>
          </ContactInner>
          <Map />
        </Container>
      </PageAnimationWrapper>
      <Footer />
    </Layout>
  )
}
export default ContactPage

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const PageAnimationWrapper = posed.div({
  visible: { y: 0, opacity: 1 },
  hidden: { y: 100, opacity: 0 },
})

const ContactInner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0px;
  @media (max-width: 425px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`
const ContacDetails = styled.div`
  h3 {
    color: ${(props) => props.theme.green};
    margin-bottom: 30px;
  }
  .intro {
    max-width: 400px;
  }
  .contacts {
    p {
      position: relative;
      padding-left: 50px;
    }
    img {
      margin-bottom: 0px;
      width: 35px;
      position: absolute;
      left: 0px;
      top: -5px;
    }
    a {
      color: ${(props) => props.theme.green};
    }
  }
  img {
    max-width: 500px;
  }
  @media (max-width: 1025px) {
    img {
      max-width: 367px;
    }
  }
  @media (max-width: 425) {
    img {
      max-width: 434px;
    }
  }
`

const FormWrapper = styled.div`
  h3 {
    color: ${(props) => props.theme.green};
    margin-bottom: 20px;
  }
  margin-bottom: 60px;
  div {
    display: grid;
    grid-gap: 2px;
    grid-template-columns: 1fr;
    margin: 20px 0px;
  }
  label {
    color: ${(props) => props.theme.green};
  }
  input {
    letter-spacing: 0.5px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.green};
    transition: all 0.3s ease-in-out;
    outline: none;
  }
  input:focus {
    background: ${(props) => props.theme.white};
    border: 2px ${(props) => props.theme.grey3};
    border: 1px solid ${(props) => props.theme.yellow};
  }
  textarea {
    letter-spacing: 0.5px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.green};
    transition: all 0.3s ease-in-out;
    outline: none;
  }
  textarea:focus {
    background: ${(props) => props.theme.white};
    border: 2px ${(props) => props.theme.grey3};
    border: 1px solid ${(props) => props.theme.yellow};
  }
  button {
    width: 200px;
    color: ${(props) => props.theme.white};
    background: ${(props) => props.theme.green};
    padding: 15px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    outline: none;
    min-width: 200px;
    :after {
      content: " →";
      color: ${(props) => props.theme.white};
    }
  }
  button:hover,
  button:focus {
    cursor: pointer;
    background: ${(props) => props.theme.gold};
    border: 1px solid ${(props) => props.theme.gold};
  }
  @media (max-width: 700px) {
    margin: 0;
    p {
      padding: 20px;
      background: #f2f2f2;
      margin: 0;
    }
    div {
      grid-template-columns: 1fr;
      text-align: left;
      grid-gap: 5px;
    }
  }
  @media (max-width: 650px) {
    input,
    button {
      min-width: 250px;
      width: 100%;
    }
  }
`

///////////////////////////////////////
// 🔎 Queries
///////////////////////////////////////
const CONTACT_PAGE_CONTENT = graphql`
  query ContactDetails {
    page: wpPage(databaseId: { eq: 8 }) {
      title
      seo {
        ...SEO
      }
      acfContact {
        phone
        introText
        email
        address {
          line1
          line2
          line3
        }
        address2 {
          line1
          line2
          line3
        }
      }
      acfPageHeader {
        heading
        subHeading
        compact
        headerImage {
          sourceUrl
        }
      }
    }
  }

  fragment SEO on WpPostTypeSEO {
    title
    focuskw
    metaDesc
    canonical
    metaKeywords
    metaRobotsNofollow
    metaRobotsNoindex
    opengraphAuthor
    opengraphDescription
    opengraphModifiedTime
    opengraphPublishedTime
    opengraphPublisher
    opengraphSiteName
    opengraphTitle
    opengraphType
    opengraphUrl
    twitterDescription
    twitterTitle
    breadcrumbs {
      text
      url
    }
    opengraphImage {
      altText
      mediaItemUrl
    }
    twitterImage {
      altText
      mediaItemUrl
    }
  }
`
